import debounce from 'debounce';

function Layout() {
  //console.log('Layout');
  this.footer = document.querySelector('.footer');
  this.header = document.querySelector('.header');
  this.content = document.querySelector('.wrapper');
  this.header_height;
  this.footer_height;

  // if (!this.header) this.header = document.querySelector('.saheader');
  // if (!this.header) this.header = document.querySelector('.ypnav');
  if (!this.header) return;

  var that = this;

  jQuery(document).ready(function() {
    that.init();
  });
}

Layout.prototype.init = function() {
  //console.log('init');
  this.setHeaderClearHeight();
  this.initHeaderScrollListener();
};

Layout.prototype.setFooterClearHeight = function() {
  this.footer_height = this.footer.getBoundingClientRect().height;
  this.wrapper.style.marginBottom = this.footer_height + 'px';
};

Layout.prototype.setHeaderClearHeight = function() {
  //console.log('setHeaderClearHeight');
  this.header_height = this.header.getBoundingClientRect().height;
  //console.log('this.header_height: ' + this.header_height);
};

Layout.prototype.initHeaderScrollListener = function() {
  //console.log('initHeaderScrollListener');
  var that = this,
    doc = document.documentElement,
    lastScroll = 0;

  window.addEventListener(
    'scroll',
    function(e) {
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      //console.log('top: ' + top);

      if (top > that.header_height / 2 && that.header.classList.contains('float')) {
        if (top < lastScroll) {
          that.header.classList.add('show');
          top++; // Fix for ie - trick that fixes showing/hiding on every scroll
        } else {
          that.header.classList.remove('show');
        }
      }

      if (top > that.header_height && that.header.classList.contains('float')) {
        that.header.classList.add('shadow');
      } else {
        that.header.classList.remove('shadow');
      }

      lastScroll = top;
    },
    false
  );
};

export default Layout;
