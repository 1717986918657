import 'bootstrap/js/dist/collapse';

export default {
    init() {
      // Collapse open accordions when another is opened
      $('.collapse').on('show.bs.collapse', function () {
        $('.collapse.show').each(function(){
          $(this).collapse('hide');
        });
      })

    },
    finalize() {
    }
};
