import Flickity from 'flickity';

function Accreditations()
{
  var accreditationsWrapper = document.querySelector(".accreditation-slides");

  if (accreditationsWrapper !== null) {
    var slides = accreditationsWrapper.querySelectorAll(".accreditation-slide");
    if (slides.length > 1) {

      // trigger redraw for transition
      accreditationsWrapper.offsetHeight;

      var accreditationsSlider = new Flickity(accreditationsWrapper, {
        //cellAlign: 'left',
        cellSelector: '.accreditation-slide',
        //arrowShape: '',
        groupCells: true,
        wrapAround: true,
        autoPlay: 5000,
        pageDots: true,
        prevNextButtons: false,
        pauseAutoPlayOnHover: false,
        imagesLoaded: true
        // selectedAttraction: 0.1,
        // friction: 1
      });

      // Previous slide arrow
      $('.accreditationsPrev').on( 'click', function(e) {
        e.preventDefault();
        accreditationsSlider.previous(true);
        //accreditationsSlider.autoplay(false);
        accreditationsSlider.pausePlayer()
      });

      // Next slide arrow
      $('.accreditationsNext').on( 'click', function(e) {
        e.preventDefault();
        accreditationsSlider.next(true);
        //accreditationsSlider.autoplay(false);
        accreditationsSlider.pausePlayer()
      });

      // // previous
      // $('.button--previous').on( 'click', function() {
      //   $carousel.flickity('previous');
      // });
      // // next
      // $('.button--next').on( 'click', function() {
      //   $carousel.flickity('next');
      // });
    }
  }
}

export default Accreditations;
