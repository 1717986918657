import Layout from '../components/layout';
import Banner from '../components/banner';
import Accreditations from '../components/accreditations';

// import Overlay from '../util/overlay';
// import Header from '../components/header';
import GMenu from "../components/g-menu";
// import SearchOverlay from "../components/search";
// import {animationEnd} from "cssevents";
import Scroller from '../util/scroller';
// import ScrollMagic from 'scrollmagic';
// import objectFitImages from 'object-fit-images';

//import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
//import "bootstrap/js/dist/modal";
//import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/util";

function callbackVisible() {
  this.classList.add('is-visible');
}

function callbackSlide() {
  this.classList.add('slide');
}

export default {
  init() {
    var html = document.querySelector("html");
        html.classList.remove('no-js');
        html.classList.add('js');

    // Mitigate IE/Edge bug showing bullets on lists which are hidden when loading the page
    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
      $('ul:hidden').each(function(){
          $(this).parent().append($(this).detach());
      });
    }

    new GMenu();
    // new SearchOverlay();
    // make alerts dismissable (newsletter signup forms, contact form)
    // $('.alert').alert();

    // this.productTiles = document.querySelectorAll(".product-tile");
    // this.elementsToFadeIn = document.querySelectorAll(".fade-in");

    // Banner carousel slider
    new Banner();

    // Accreditations carousel slider
    new Accreditations();

    this.testimonialBlocks = document.querySelectorAll(".testimonials-block");

    this.fadeIn = document.querySelectorAll(".fadeIn");

    this.slideUp = document.querySelectorAll(".slideUp");
    this.fromLeft = document.querySelectorAll(".fromLeft");
    this.fromRight = document.querySelectorAll(".fromRight");

    // Listen to tab events to enable outlines (accessibility improvement)
    document.body.addEventListener('keyup', function(e) {
      if (e.which === 9) /* tab */ {
        document.documentElement.classList.remove('no-focus-outline');
      }
    });
  },
  finalize() {
    // objectFitImages('img.object-fit-cover');

    // var overlays = document.querySelectorAll('.overlay');
    // var overlay_id;
    // var overlay;

    // if (this.elementsToFadeIn) {
    //   for (var f = 0; f < this.elementsToFadeIn.length; f++) {
    //       this.elementsToFadeIn[f].scroll = new Scroller(this.elementsToFadeIn[f], {
    //           callback: callbackVisible,
    //           trigger: "top",
    //           triggerOffset: 100,
    //           // triggerOffsetPercentage: 25,
    //           once: true
    //       });
    //   }
    // }

    if (this.testimonialBlocks) {
      for (var t = 0; t < this.testimonialBlocks.length; t++) {
        this.testimonialBlocks[t].scroll = new Scroller(
            this.testimonialBlocks[t], {
                callback: callbackVisible,
                trigger: "top",
                triggerOffsetPercentage: 20,
                once: true
            } 
        );
      }
    }

    if (this.slideUp) {
        for (var s = 0; s < this.slideUp.length; s++) {
            this.slideUp[s].scroll = new Scroller(
              this.slideUp[s], {
                callback: callbackSlide,
                trigger: "top",
                // triggerOffset: 0,
                triggerOffsetPercentage: 20,
                once: true
            });
        }
    }

    if (this.fromLeft) {
      for (var f = 0; f < this.fromLeft.length; f++) {
          this.fromLeft[f].scroll = new Scroller(
            this.fromLeft[f], {
              callback: callbackSlide,
              trigger: "top",
              triggerOffsetPercentage: 20,
              once: true
          });
      }
    }

    if (this.fromRight) {
      for (var r = 0; r < this.fromRight.length; r++) {
          this.fromRight[r].scroll = new Scroller(
            this.fromRight[r], {
              callback: callbackSlide,
              trigger: "top",
              triggerOffsetPercentage: 20,
              once: true
          });
      }
    }

    const layout = new Layout();


    // var videos = document.querySelectorAll('video');
    // for ( var i=0, len = videos.length; i < len; i++ ) {
    //   var video = videos[i];
    //   // resume autoplay for WebKit
    //   video.play();
    //   video.addEventListener("loadeddata", onLoadedData, false);
    // }
  }

    // function onLoadedData(event)
    // {
    //     var cell = banner.getParentCell( event.target );
    //     banner.cellSizeChange( cell && cell.element );
    // }

    // if (overlays !== null) {
    //     for (var i = 0; i < overlays.length; i++) {
    //         overlay_id = overlays[i].id;
    //         overlay = new Overlay({
    //           trigger: document.querySelectorAll('.overlay__button--' + overlay_id),
    //           events: {
    //             beforeClose: function() {
    //               var videoIframe = document.getElementById(overlay_id+"iframe");
    //               // var iframeWindow = videoIframe.contentWindow ? videoIframe.contentWindow : videoIframe.contentDocument.defaultView;
    //               //     iframeWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    //               //jQuery("#"+overlay_id+"iframe")[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    //                   // videoIframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    //               // var iframe = element.querySelector("iframe");
    //               var video = videoIframe.querySelector('video');

    //               if (videoIframe) {
    //                   var iframeSrc = videoIframe.src;
    //                   videoIframe.src = iframeSrc;
    //               }

    //               if (video) {
    //                 video.pause();
    //               }
    //             }
    //           }
    //         });
    //     }
    // }

      // ScrollMagic animations
      // var mobileMenu = document.getElementById("gn-menu-main");
      // var isMobile = !!(mobileMenu.offsetWidth || mobileMenu.offsetHeight || mobileMenu.getClientRects().length);

      // var dropShadowMultiplier = (isMobile) ? 10 : 15 ;

      // var scrollController = new ScrollMagic.Controller();

      // // Instagram feed block
      // var instagramRow = document.getElementById("instagram-row");
      // if (instagramRow) {
      //   if (!isMobile && instagramRow.childNodes.length) {
      //       var instaItems = document.querySelectorAll('.instagram-col:nth-child(2n + 1)');
      //       var instaScene = new ScrollMagic.Scene({
      //           triggerElement: '#instagram-block',
      //           duration: Math.round(window.innerWidth / 2),
      //           offset: -300
      //       })
      //       .on('progress', function(event) {
      //           for (var i = 0; i < instaItems.length; i++) {
      //             instaItems[i].style.transform = 'translateY(' + event.progress * 20 +'%)';
      //           }
      //       })
      //       .addTo(scrollController);
      //   }
      // }

          // for (var i = 0; i < rightDropShadows.length; i++) {
          //   rightDropShadows[i].style.transform = 'translate(+' + event.progress * 7 +'%, +' + event.progress * 7 + '%)';
          // }

      // Drop-shadow scroll animation
      // var leftImgRightText = document.querySelectorAll('.left-img-right-text');

      // if (leftImgRightText.length) {
      //   for (var i = 0; i < leftImgRightText.length; i++) {
      //     var leftDropShadows = leftImgRightText[i].querySelectorAll('.drop-shadow-left .drop-shadow');
      //     new ScrollMagic.Scene({
      //       triggerElement: leftImgRightText[i],
      //       duration: window.innerHeight,
      //       offset: -300
      //     })
      //     .on('progress', function(event) {
      //       for (var i = 0; i < leftDropShadows.length; i++) {
      //         leftDropShadows[i].style.transform = 'translate(-' + event.progress * dropShadowMultiplier +'%, -' + event.progress * dropShadowMultiplier + '%)';
      //       }
      //     })
      //     .addTo(scrollController);
      //   }
      // }

      // var rightImgLeftText = document.querySelectorAll('.right-img-left-text');

      // if (rightImgLeftText.length) {
      //   for (var i = 0; i < rightImgLeftText.length; i++) {
      //     var rightDropShadows = rightImgLeftText[i].querySelectorAll('.drop-shadow-right .drop-shadow');
      //     new ScrollMagic.Scene({
      //       triggerElement: rightImgLeftText[i],
      //       duration: window.innerHeight,
      //       offset: -300
      //     })
      //     .on('progress', function(event) {
      //       for (var i = 0; i < rightDropShadows.length; i++) {
      //         rightDropShadows[i].style.transform = 'translate(+' + event.progress * dropShadowMultiplier +'%, -' + event.progress * dropShadowMultiplier + '%)';
      //       }
      //     })
      //     .addTo(scrollController);
      //   }
      // }

      // var rightDropShadows = document.querySelectorAll('.drop-shadow-right .drop-shadow');

      // if ($('.left-img-right-text').length) {
      //   var dropScene = new ScrollMagic.Scene({
      //       triggerElement: '.left-img-right-text',
      //       duration: window.innerHeight,
      //       offset: -300
      //   })
      //   .on('progress', function(event) {

      //     for (var i = 0; i < leftDropShadows.length; i++) {
      //       leftDropShadows[i].style.transform = 'translate(-' + event.progress * 7 +'%, -' + event.progress * 7 + '%)';
      //     }

      //     // $('.intro__image-bg').css('transform', 'translate(-' + event.progress * 7 +'%, -' + event.progress * 7 + '%');
      //     // $('.intro .progressive-image').css('transform', 'translate(' + event.progress * 7 +'%, ' + event.progress * 7 + '%');
      //   })
      //   .addTo(this.scrollController);
      // }
}
