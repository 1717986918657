import Flickity from 'flickity';

function Banner()
{
  // console.log("Banner");

  var banner = document.querySelector('.banner.js');

  if (banner !== null) {
    banner.classList.remove("d-none");

    // trigger redraw for transition
    banner.offsetHeight;

    require('flickity-imagesloaded');

    var $banner = new Flickity(banner, {
        //cellAlign: 'left',
        // cellSelector: '.banner__item',
        wrapAround: true,
        autoPlay: 5000,
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true,
        pauseAutoPlayOnHover: false
        // selectedAttraction: 0.1,
        // friction: 1
    });

    // Flickity instance
    // var flkty = $banner.data('flickity');
    // var flkty = $banner;

    // elements
    var $cellButtonGroup = $('.banner-nav');
    var $cellButtons = $cellButtonGroup.find('li');

    // update selected cellButtons
    $banner.on('select', function() {
      $cellButtons.filter('.is-selected').removeClass('is-selected');
      $cellButtons.eq($banner.selectedIndex).addClass('is-selected');
    });

    // select cell on click
    $cellButtonGroup.on('click', 'li', function(e) {
      e.preventDefault();
      var index = $(this).index();
      $banner.select(index);
      //$banner.autoplay(false);
      $banner.pausePlayer()
    });

    // Previous slide arrow
    $('.bannerPrev').on( 'click', function(e) {
      e.preventDefault();
      $banner.previous(true);
      //$banner.autoplay(false);
      $banner.pausePlayer()
    });

    // Next slide arrow
    $('.bannerNext').on( 'click', function(e) {
      e.preventDefault();
      $banner.next(true);
      //$banner.autoplay(false);
      $banner.pausePlayer()
    });
  }
}

export default Banner;
