// import Map from '../components/map';

export default {
  init() {
    // new Map();
  },
  finalize() {

  }
}
