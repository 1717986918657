import 'bootstrap/js/src/alert';
import 'bootstrap/js/src/button';
import 'bootstrap/js/src/util';
//import ajax from "@fdaciuk/ajax";

export default {
    init() {

    },
    finalize() {
    }
};
